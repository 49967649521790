<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="4">
              <s-input
                title="姓名"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :sm="4">
              <s-input
                title="手机号"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.Mobile"
              />
            </a-col>
            <a-col :sm="5">
              <s-radio
                title="性别"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.Sex"
                :options="[
                  {
                    label: '先生',
                    value: 1,
                  },
                  {
                    label: '女士',
                    value: 2,
                  },
                ]"
              />
            </a-col>
            <a-col :sm="5">
              <s-radio
                title="是否挂账"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.AllowCredit"
                :options="[
                  {
                    label: '是',
                    value: true,
                  },
                  {
                    label: '否',
                    value: false,
                  },
                ]"
              />
            </a-col>
            <a-col :sm="5">
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>

              <a-button
                type="primary"
                @click="$refs.eForm.open()"
                style="margin-left: 10px"
              >
                创建会员
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
          @change="onChangeTable"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'customerName'">
              <div class="username">
                <span>{{ record.customerName }}</span>
                <span
                  v-if="
                    record.customerName !== '会员卡' &&
                    record.customerName !== '匿名会员'
                  "
                >
                  <span>({{ ["未知", "先生", "女士"][record.customerSex] }})</span>
                  <span v-if="record.customerSex == 1" class="sex man">
                    <ManOutlined />
                  </span>
                  <span v-else-if="record.customerSex == 2" class="sex feman">
                    <WomanOutlined />
                  </span>
                  <span v-else class="sex">
                    <ManOutlined />
                  </span>
                </span>
              </div>
            </template>

            <template v-if="column.key === 'customerMobile'">
              <span v-if="record.customerName !== '会员卡'">{{record.customerMobile}}</span>
              <span v-else></span>
            </template>

            <template v-if="column.key === 'allowCredit'">
              {{ record.allowCredit ? "允许挂账" : "禁止挂账" }}
            </template>

            <template v-if="column.key === 'balance'">
              <span v-if="record.balance >= 0">{{ record.balance }}</span>
              <router-link :to="'/counter/orders?name='+record.customerName + '&payChannel=8'" v-else>{{ record.balance }}</router-link>
            </template>
            <template v-if="column.key === 'totalOutlay'">
              <router-link :to="'/counter/orders?name='+record.customerName">{{ record.totalOutlay }}</router-link>
            </template>

            <template v-if="column.key === 'lastCostTime'">
              <span v-if="record.lastCostTime">{{
                $filters.formatDate(record.lastCostTime)
              }}</span>
              <span v-else>暂无消费</span>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>编辑账户</template>
                <a @click.stop="$refs.eForm.open(record)">
                  <EditOutlined />
                </a>
              </a-tooltip>

              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>账户充值</template>
                <a @click.stop="$refs.finAccount.open(record)">
                  <icon-font type="icon-caiwu1" />
                </a>
              </a-tooltip>

              <a-divider type="vertical" />

              <a-tooltip v-if="record.allowCredit">
                <template #title>清账</template>
                <a @click.stop="$refs.payBack.open(record)">
                  <PayCircleOutlined />
                </a>
              </a-tooltip>
              <span v-else style="color: #ccc">
                <PayCircleOutlined />
              </span>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <FinAccount ref="finAccount" @ok="getDataList(page.page)" />
    <PayBack ref="payBack" @ok="getDataList(page.page)" />
    <EForm ref="eForm" @ok="getDataList(page.page)" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  ManOutlined,
  WomanOutlined,
  PayCircleOutlined,
  EditOutlined
} from '@ant-design/icons-vue'
import { FinAccountClass } from '@/apis/fin'
import page from '@/mixins/page'
import FinAccount from '@/components/counter/forms/member/FinAccount'
import PayBack from '@/components/counter/forms/member/PayBack'
import EForm from '@/components/counter/forms/member/Form'

const api = new FinAccountClass()
export default defineComponent({
  mixins: [page],
  components: {
    ManOutlined,
    WomanOutlined,
    FinAccount,
    PayBack,
    PayCircleOutlined,
    EForm,
    EditOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    return {
      loading,
      height,
      width
    }
  },

  data () {
    return {
      sortOrder: '',
      columns: [
        {
          title: '姓名',
          key: 'customerName',
          dataIndex: 'customerName',
          width: 200,
          fixed: 'left'
        },
        {
          title: '手机号码',
          key: 'customerMobile',
          dataIndex: 'customerMobile',
          width: 110
        },
        {
          title: '客户经理',
          key: 'managerName',
          dataIndex: 'managerName',
          width: 100
        },
        {
          title: '余额',
          key: 'balance',
          dataIndex: 'balance',
          width: 100,
          sorter: true
        },
        {
          title: '总消费',
          key: 'totalOutlay',
          dataIndex: 'totalOutlay',
          width: 100,
          sorter: true
        },
        {
          title: '总充值',
          key: 'totalIncome',
          dataIndex: 'totalIncome',
          width: 100
        },
        {
          title: '允许挂账',
          key: 'allowCredit',
          dataIndex: 'allowCredit',
          width: 100
        },
        {
          title: '预约次数',
          key: 'bookTimes',
          dataIndex: 'bookTimes',
          width: 100
        },
        {
          title: '成交次数',
          key: 'successTimes',
          dataIndex: 'successTimes',
          width: 100
        },
        {
          title: '取消次数',
          key: 'cancelTimes',
          dataIndex: 'cancelTimes',
          width: 100
        },
        {
          title: '不来次数',
          key: 'quitTimes',
          dataIndex: 'quitTimes',
          width: 100
        },
        {
          title: '最近消费时间',
          key: 'lastCostTime',
          dataIndex: 'lastCostTime',
          width: 160
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 110,
          fixed: 'right'
        }
      ]
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    onChangeTable (pagination, filters, sorter) {
      const { field, order } = sorter

      if (order && field) {
        this.queryParams.Orderby = order
        this.queryParams.OrderField = field
        this.getDataList(1)
      } else {
        this.resetFun()
      }
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
.username {
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  span {
    margin-left: 5px;
    &.sex {
      font-size: 12px;
      color: #ccc;
      &.man {
        color: rgb(66, 48, 228);
      }
      &.feman {
        color: rgb(228, 48, 48);
      }
    }
  }
}
.search-box {
  position: relative;
  z-index: 9;
}
</style>
